import './ImageDetection.css';
import ImageCompressor from './ImageCompressor';
import { Upload } from './Upload';

export function ImageDetection() {
  return (
    <div>
      <div className="TryYourSelf flex flex-col items-center py-20">
        <div className="w-1/2 text-center">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-4xl">
            Try your own
          </h1>
          <p className="mt-2 text-lg leading-8 text-white">
            Upload your own image and compare how AcuityAI works.
          </p>
        </div>
      </div>
      <div className="mt-16 flex  flex-row justify-center">
        <Upload />
      </div>
    </div>
  );
}
