import { CodecGraph } from './components/CodecGraph';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { HowWorks } from './components/HowWorks';
import { ImageDetection } from './components/ImageDetection';

export default function App() {
  return (
    <>
      <Header />
      <HowWorks />
      <ImageDetection />
      <CodecGraph />
      <Footer />
    </>
  );
}
