import { MouseEvent, useRef, useState } from 'react';
import { ImageMetadata } from './ImageMetadata';
import { formatBytes, formatReduction } from '../utils/formatBytes';
import { useElementSize } from '../hooks/useElementSize';
import csx from 'classnames';

function respectMinMax(current: number, min: number, max: number) {
  if (current < min) return min;
  if (current > max) return max;
  return current;
}

export function ImageComparator({
  className,
  jpg,
  aai,
}: {
  className?: string;
  jpg: string;
  aai: string;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [sliderPosition, setSliderPosition] = useState(50);
  const {
    setRef: imgRef,
    size: imageSize,
    reload: imageLoad,
  } = useElementSize();

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current) return;
    const containerRect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - containerRect.left;
    const position = respectMinMax(x, 10, containerRect.width - 10);
    const newPosition = (position / containerRect.width) * 100;

    setSliderPosition(newPosition);
  };

  return (
    <div
      className={csx([
        'flex w-full flex-1 justify-center overflow-hidden md:px-0',
        className,
      ])}
    >
      <div
        className="relative flex h-full w-full cursor-move"
        ref={containerRef}
        onMouseMove={handleMouseMove}
      >
        <div className="relative flex h-full w-full items-center justify-center object-contain">
          <img
            ref={imgRef}
            src={aai}
            className="invisible max-h-full max-w-full"
            onLoad={imageLoad}
          />
          <div
            style={{
              ...imageSize,
              objectFit: 'cover',
              opacity: imageSize.height ? '1' : '0',
            }}
            className="absolute z-10 max-h-full max-w-full bg-white transition-opacity duration-200 ease-linear"
          >
            <div
              className="absolute left-0 top-0 flex h-full w-full items-end justify-end"
              style={{
                backgroundColor: '#000000',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundPositionY: 'center',
                backgroundImage: `url(${jpg})`,
              }}
            />
            <div
              className="absolute left-0 top-0 flex h-full w-full items-end justify-start"
              style={{
                backgroundColor: '#000000',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'center',
                backgroundPositionY: 'center',
                backgroundImage: `url(${aai})`,
                clipPath: `polygon(0 0, ${sliderPosition}% 0, ${sliderPosition}% 100%, 0 100%)`,
              }}
            />
            <div
              className="absolute top-0 flex h-full items-start justify-start gap-2"
              style={{
                left: `${sliderPosition}%`,
                transform: 'translateX(-50%)',
                pointerEvents: 'none',
              }}
            >
              <div className="mt-2 w-fit rounded-xl bg-white px-5 text-base">
                AcuityAI
              </div>
              <div className="h-full w-[6px] bg-white" />
              <div className="mt-2 w-fit rounded-xl bg-white px-5 text-base">
                JPEG 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
