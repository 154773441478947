import React, { useState } from 'react';

interface Image {
  id: number; // Ensure id is typed as number
  url: string;
  name?: string;
}

type ProcessedImages = {
  [key: number]: string[]; // Define the structure more explicitly
};

const ImageSelector: React.FC<{ onImagesSelected: (imageUrls: string[]) => void }> = ({ onImagesSelected }) => {
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null);
  // raw images
  const rawImages: Image[] = [
    { id: 1, url: './T6100109.jpg', name: 'Raw Image 1' },
    { id: 2, url: './ny_raw.png', name: 'Raw Image 2' },
    { id: 3, url: './evening_raw.jpeg', name: 'Raw Image 3' },
    { id: 4, url: './day_raw.jpeg', name: 'Raw Image 1' },
    { id: 5, url: './crossing_raw.jpeg', name: 'Raw Image 2' },
    { id: 6, url: './ca_sar.jpg', name: 'Raw Image 3' }, 
  ];

  //processed images associated with each raw image
  const processedImages: ProcessedImages = {
    1: [
      './T6_stat_table.png',
      './T6_aai_comp.png',
      './T6_jpg_comp.png',
      './T6_aai_det.png',
      './T6_jpg_det.png',
    ],
    2: [
      './ny_stat_table.png',
      './ny_aai_comp.png',
      './ny_jpg_comp.png',
      './ny_aai_det.png',
      './ny_jpg_det.png',
    ],
    3: [
      './ev_stat_table.png',
      './ev_aai_comp.png',
      './ev_jpg_comp.png',
      './ev_aai_det.png',
      './ev_jpg_det.png',
    ],
    4: [
      './dy_stat_table.png',
      './dy_aai_comp.png',
      './dy_jpg_comp.png',
      './dy_aai_det.png',
      './dy_jpg_det.png',
    ],
    5: [
      './cr_stat_table.png',
      './cr_aai_comp.png',
      './cr_jpg_comp.png',
      './cr_aai_det.png',
      './cr_jpg_det.png',
    ],
    6: [
      './ca_stat_table.png',
      './ca_aai_comp.png',
      './ca_jpg_comp.png',
      './ca_aai_det.png',
      './ca_jpg_det.png',
    ],
  };

  // Handle raw image click
  const handleImageClick = (imageId: number) => {
  // If the clicked image is already selected, unselect it
  if (selectedImageId === imageId) {
    setSelectedImageId(null); // Unselect the image
    onImagesSelected([]);
  } else {
    setSelectedImageId(imageId); // Select the image
    const selectedRawImage = rawImages.find(image => image.id === imageId);
    if (selectedRawImage) {
      const selectedProcessedImages = processedImages[imageId] || [];
      onImagesSelected([selectedRawImage.url, ...selectedProcessedImages]);
    }
  }
};

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      paddingBottom: '2vh', 
      marginBottom: '2vh', 
      gap: '2vh',
    }}>
      {rawImages.map(image => (
        <img 
          key={image.id} 
          src={image.url} 
          alt={image.name} 
          onClick={() => handleImageClick(image.id)} 
          style={{
            width: '10vw',
            height: 'auto',
            cursor: 'pointer',
            border: selectedImageId === image.id ? '3px solid red' : 'none',
            transition: 'border 0.5s ease',
          }} 
        />
      ))}
    </div>
  );
};

export default ImageSelector;
