// src/components/ImageCompressor.tsx
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios'; 
import './ImageCompressor.css'; 
import ImageSelector from './ImageSelector';
import { ImageComparator } from './ImageComparator';

interface ProcessingResult {
  // path to the processed image
  decoded_image?: string;
  // path to the stats file
  stat_file?: string;
}

interface codecStats {
  'Binary File Size'?: string;
  'Compression Ratio'?: string;
  'BPP'?: string;
  'PSNR'?: string;
  'SSIM'?: string;
  'Encoding Time'?: string;
};

type StatItem = {
  name: keyof codecStats; 
  acuity: string;
  jpeg: string;
};

type StatsArray = StatItem[];

const styles = {
  section:
    'mb-14 flex w-full max-w-[1000px] flex-col items-center justify-center',
  title: 'mb-6 text-center text-2xl tracking-tight text-black sm:text-2xl',
};

const ImageCompressor = () => {
  // State for the selected file
  const [file, setFile] = useState<File | null>(null);
  // State for the image preview URL
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  // warning state
  const [warningMessage, setWarningMessage] = useState('');
 
  // State for the quality slider
  const [quality, setQuality] = useState(7);
  // State to indicate processing
  const [JPEGStats, setJpegStats] = useState<codecStats>({'Binary File Size': '',
                                                          'Compression Ratio': '',
                                                          'BPP': '',
                                                          'PSNR': '',
                                                          'SSIM': '',
                                                          'Encoding Time': ''
  });
  const [AcuityAIStats, setAcuityaiStats] = useState<codecStats>({'Binary File Size': '',
                                                          'Compression Ratio': '',
                                                          'BPP': '',
                                                          'PSNR': '',
                                                          'SSIM': '',
                                                          'Encoding Time': ''
  });
  const [imageMetadata, setImageMetadata] = useState({
    width: 0,
    height: 0,
    fileSize: '',
  });
  // State to hold processing results
  const [aaiResults, setAaiResults] = useState<{aai: ProcessingResult} | null>(null);

  const [jpgResults, setJpgResults] = useState<{ jpg: ProcessingResult } | null>(null);
  
  const [targetBPP, setTargetBPP] = useState('0');
  
  const [detInAai, setDetInAai] = useState('');
  const [detInJpg, setDetInJpg] = useState('');

  const [disableDetect, setDisableDetect] = useState(true);

  const [reconImages, setReconImages] = useState(false);

  const [processingState, setProcessingState] = useState({isProcessing: false,
                                                          processingText: '',
                                                          processingDone: false,
                                                          showCompletionMessage: false,
                                                        });

  const [detResults, setDetResults] = useState<{det: {aai: string, jpg: string}} | null>(null);


  const [statsForDisplay, setStatsForDisplay] = useState<StatsArray | null>(
    null
  );

  const [selectedImageUrls, setSelectedImageUrls] = useState<string[]>([]);

  // Reference to the file input element
  const fileInputRef = useRef<HTMLInputElement>(null);

  const imageRef = useRef<HTMLImageElement>(null);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFile = e.dataTransfer.files[0];
      prepFileUpload(droppedFile);
      e.dataTransfer.clearData();
    }
  };
  
  function prepFileUpload(file: File) {
    // const supportedTypes = ['image/png', 'image/jpeg', 'image/bmp'];
    // if (!supportedTypes.includes(file.type)) {
    //   convertToSupportedFormat(file)
    //     .then((convertedFile) => {
    //       processUploadFile(convertedFile);
    //     })
    //     .catch((error) => {
    //       // Handle conversion errors gracefully
    //       console.error('Error converting image:', error);
    //     });
    // } else {
    //   processUploadFile(file);
    // }
    convertToSupportedFormat(file)
    .then((convertedFile) => {
      processUploadFile(convertedFile);
    })
    .catch((error) => {
      // Handle conversion errors gracefully
      console.error('Error converting image:', error);
    });
  }

  const processUploadFile = async (selectedFile: File) => {
    setAaiResults(null); setDetResults(null); setJpgResults(null)
    setFile(selectedFile); // Set the file state

    // Call handleUpload immediately after setting the file
    const isUploadSuccessful = await uploadFile(selectedFile);
    if (!isUploadSuccessful) {
      return; 
    }
    // Then, read the file for preview
    const reader = new FileReader();
    reader.onloadend = () => {
      const newUrl = (reader.result as string) ?? '';
      setImagePreviewUrl(newUrl);
      setTimeout(adjustDivSize, 100);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      prepFileUpload(e.target.files[0]);
    }
  };

  function adjustDivSize() {
    const element = document.querySelector(
      '.aspect-ratio-square'
    ) as HTMLElement;
    if (element) {
      // Get the computed height of the element
      const height = element.offsetHeight;
      // Set the width equal to the height
      element.style.width = `${height}px`;
    }
  }

  useEffect(() => {
    window.addEventListener('resize', adjustDivSize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', adjustDivSize);
    };
  }, []);


  const extractStats = (statsText: string) => {
    let lines = statsText.split(/\r?\n/);
    // Create a new object with the same keys as JPEGStats, but with null values
    let stats: codecStats = Object.keys(JPEGStats).reduce((acc, key) => {
      (acc as any)[key] = null;
      return acc;
    }, {} as codecStats);
    lines.forEach((line) => {
      const lineParts = line.split(':');
      const key = lineParts[0].trim();
      const value = lineParts[1]?.trim();
      if (key in stats) {
        if (key === 'Compression Ratio') {
          const binSize = parseFloat(stats['Binary File Size']!.replace(' kB', ''));
          const rawImgKb = parseFloat(imageMetadata.fileSize) * 1024;
          const compressionRatio = rawImgKb/binSize;    
          // Format the compression ratio as a string with two decimal places and store it
          stats['Compression Ratio'] = compressionRatio.toFixed(2);
        }
        else {
          (stats as any)[key] = value; 
        }
      }
    });
    return stats;
  };

  useEffect(() => {
    const fetchStats = async (url: RequestInfo | URL, setter: { (value: React.SetStateAction<codecStats>): void; (value: React.SetStateAction<codecStats>): void; (arg0: codecStats): void; }) => {
      try {
        const response = await fetch(url);
        const text = await response.text();
        const processedStats = extractStats(text);
        setter(processedStats);
      } catch (error) {
        console.error(`Failed to load stats from ${url}:`, error);
      }
    };
    if (jpgResults?.jpg?.stat_file) {
      fetchStats(jpgResults.jpg.stat_file, setJpegStats);
    }

    if (aaiResults?.aai?.stat_file) {
      fetchStats(aaiResults.aai.stat_file, setAcuityaiStats);
    }
  }, [aaiResults,jpgResults]);

  useEffect(() => {
    const statsToDisplay = getStatsForDisplay(AcuityAIStats, JPEGStats);
    setStatsForDisplay(statsToDisplay);
  }, [AcuityAIStats, JPEGStats]);

  const handleUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!file) {
      console.log('Please select a file to upload.');
      return;
    }

    await uploadFile(file);
  };

  const uploadFile = async (file: File) => {
    if (!file) {
      console.log('Please select a file to upload.');
      return;
    }
    const formData = new FormData();
    formData.append('image', file);

    try {
      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Capture and set image metadata from the server response
      const { width, height, fileSize } = uploadResponse.data;
      setImageMetadata({ width, height, fileSize });
      // Check if both width and height are less than 160
      if (width < 160 || height < 160) {
        setWarningMessage(
          `Warning: Image dimensions ${width}x${height} are less than 160x160, upload a larger image.`
        );
        setImagePreviewUrl('');
        return false;
      }
      setWarningMessage('');
      return true;
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  function createFormData(file: string | Blob, quality: number, codec = 'aai', action = 'compress') {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('acuity-quality', (8 - quality).toString()); 
    formData.append('codec', codec);
    formData.append('action', action); 
    return formData;
  }
  
  // Define the function to process FormData
  const processFormData = async (formData: any) => {
    try {
      // Define the backend URL (you might have it in your environment variables)
      const backendUrl = `${process.env.REACT_APP_BACKEND_URL}/process`;

      // Make a POST request with Axios
      const response = await axios.post(backendUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Return the response data
      return response.data;
    } catch (error) {
      console.error('Error processing form data:', error);
      throw error; 
    }
  };

  // Handle the selection of images
  const handleImagesSelected = (imageUrls: string[]) => {
    setSelectedImageUrls(imageUrls); 
  };


  const compressAAI = async () => {
    if (!file) {
      setWarningMessage('Please select a file to upload.');
      return;
    }

    // Check if both width and height are less than 160
    if (imageMetadata.width < 160 || imageMetadata.height < 160) {
      setWarningMessage(
        `Warning: Image dimensions ${imageMetadata.width}x${imageMetadata.height} are less than 160x160, upload a larger image.`
      );
      return;
    }
    setProcessingState(prevState => ({
      ...prevState,
      isProcessing: true,
      processingText: 'Compressing with AcuityAI...',
    }));
    
    setAaiResults(null);

    try {
      const aaiFormData = createFormData(file, quality, 'aai', 'compress');
      const aaiProcessResponse = await processFormData(aaiFormData);
      setTargetBPP(aaiProcessResponse.bpp);
      const acuityaiFilename = aaiProcessResponse?.acuityai?.decoded_image
        .split('/')
        .pop();
      
      const newResults = {
        aai: {
          decoded_image: `${process.env.REACT_APP_BACKEND_URL}/image/${acuityaiFilename}`,
          stat_file: `${process.env.REACT_APP_BACKEND_URL
            }/image/${acuityaiFilename.replace(
              'decoded_image.png',
              'stat_file.txt'
            )}`,
        }
      }
      setDetInAai(aaiProcessResponse?.acuityai?.decoded_image);
      setAaiResults(newResults);

      //Processing done
      setProcessingState(prevState => ({
        ...prevState,
        isProcessing: false,
      }));

    } catch (error) {
      console.error('Error processing image:', error);
      setProcessingState(prevState => ({
        ...prevState,
        isProcessing: false,
      }));
    }

  }

  const compressJPEG = async () => {

    if (!file) {
      setWarningMessage('Please select a file to upload.');
      return;
    }

    setProcessingState(prevState => ({
      ...prevState,
      processingDone: false,
      isProcessing: true,
      processingText: 'Now compressing with JPEG...',
    }));
  
    setJpgResults(null);

    const pth = aaiResults?.aai?.decoded_image;
    const basename = pth?.substring(pth.lastIndexOf('/') + 1); 
    const jpg_file = basename?.replace('_acuityai_decoded_image', '');

    const jpgFormData = createFormData(file, quality, 'jpeg', 'compress');
    jpgFormData.append('target-bpp', (targetBPP).toString());
    jpgFormData.append('jpg_in', jpg_file? jpg_file : '');
    const jpgProcessResponse = await processFormData(jpgFormData);
    
    const jpegFilename = jpgProcessResponse?.jpeg?.decoded_image
        .split('/')
        .pop();
    const newResults = {
      jpg: {
          decoded_image: `${process.env.REACT_APP_BACKEND_URL}/image/${jpegFilename.replace()}`,
          stat_file: `${process.env.REACT_APP_BACKEND_URL
          }/image/${jpegFilename.replace(
            'decoded_image.png',
            'stat_file.txt'
          )}`,
      },
    } 
    setDetInJpg(jpgProcessResponse?.jpeg?.decoded_image);
    setJpgResults(newResults);
    setProcessingState(prevState => ({
      ...prevState,
      isProcessing: false,
      processingDone: true,
    }));
    
  }

  const detectObjects = async () => {

    setProcessingState(prevState => ({
      ...prevState,
      isProcessing: true,
      processingDone: false,
      processingText: 'Running object dection with YOLOv5...',
    }));
   
    setDetResults(null);

    const detectionFormData = createFormData('xyz', quality, 'yolo', 'detect');
    detectionFormData.append('aai_in', detInAai);
    detectionFormData.append('jpg_in', detInJpg);

    await processFormData(detectionFormData);
    const aaifile = detInAai?.split('/').pop();
    const jpgfile = detInJpg?.split('/').pop();

    const newResults = {
      det: {
          aai: `${
              process.env.REACT_APP_BACKEND_URL
            }/image/${aaifile?.replace(
              'decoded_image.png',
              `detection_res.png`
          )}`,
          jpg: `${
              process.env.REACT_APP_BACKEND_URL
            }/image/${jpgfile?.replace(
              'decoded_image.png',
              `detection_res.png`
            )}`,
      }     
    }
    setDetResults(newResults);
    setProcessingState(prevState => ({
      ...prevState,
      isProcessing: false,
      processingDone: true,
    }));
    setDisableDetect(true)
  }

   useEffect(() => {
    if (processingState.processingDone) {
      // Show the message
      setProcessingState(prevState => ({ ...prevState, showCompletionMessage: true }));
      
      // Set a timeout to hide the message after 1.25 seconds
      const timer = setTimeout(() => {
        setProcessingState(prevState => ({ ...prevState, showCompletionMessage: false }));
      }, 1250);

      return () => clearTimeout(timer);
    }
  }, [processingState.processingDone]);

  async function processImages() {
    try {
      // Ensure file is selected and valid before starting the process
      if (!file) {
        console.log('Please select a file to upload.');
        return;
      }

      // Execute each function sequentially, waiting for each to complete before moving to the next
      await compressAAI();
    } catch (error) {
      // Log or handle any errors that occurred during the processing
      console.error('An error occurred during processing:', error);
    }
  }


  useEffect(() => {
        if (aaiResults) {
          compressJPEG();
          setReconImages(false);
        }
      },[aaiResults]);

  async function reconstructImages() {
    setProcessingState(prevState => ({
      ...prevState,
      isProcessing: true,
      processingDone: false,
      processingText: 'Running image reconstruction...',
    })); 
    // Wait for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 2000));
    setProcessingState(prevState => ({
      ...prevState,
      isProcessing: false,
      processingDone: true,
    }));
    // After waiting, set the state to true
    setReconImages(true);
    setDisableDetect(false);
  }

  const handleQualityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuality(parseInt(e.target.value, 10));
  };

  useEffect(() => {
    const updateBoxHeight = () => {
      if (imageRef.current) {
        const height = imageRef.current.clientHeight;
        const resultBoxes = document.querySelectorAll('.result-box');
        resultBoxes.forEach((box) => {
          const htmlBox = box as HTMLElement;
          htmlBox.style.height = `${height}px`;
        });
      }
    };

    const img = imageRef.current;
    if (img && img.complete) {
      updateBoxHeight();
    } else if (img) {
      img.onload = updateBoxHeight;
    }
    return () => {
      if (img) {
        img.onload = null;
      }
    };
  }, [imagePreviewUrl]);

  const convertToSupportedFormat = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const objectURL = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(img, 0, 0);
          canvas.toBlob((blob) => {
            if (blob) {
              const convertedFile = new File([blob], 'converted-image.png', {
                type: 'image/png',
              });
              resolve(convertedFile);
            } else {
              reject(new Error('Blob conversion failed'));
            }
          }, 'image/png');
        } else {
          reject(new Error('Unable to create canvas context'));
        }
        // Release the object URL to avoid memory leaks
        URL.revokeObjectURL(objectURL);
      };
      img.onerror = () => {
        URL.revokeObjectURL(objectURL);
        reject(new Error('Image loading failed'));
      };
      img.src = objectURL;
    });
  };

  const getStatsForDisplay = (acuityStats: codecStats, jpegStats: codecStats) => {
    const keys = Object.keys(acuityStats) as Array<keyof codecStats>;
    return keys.map((key) => ({
      name: key,
      acuity: acuityStats[key] || '',
      jpeg: jpegStats[key] || '',
    }));
  };
  
  return (
    <div>
      {processingState.isProcessing && (
        <>
          <div id="overlay"></div>
          <div id="processing-bar">
            {processingState.processingText}
            <div className="progress-outer">
              <div className="progress-inner"></div>
            </div>
          </div>
        </>
      )}

      {processingState.showCompletionMessage && (
        <div id="processing-bar">
          <span className="message-text">
            Done, results available!
          </span>
        </div>
      )}

      {warningMessage && (
        <>
          <div className="warning-message">{warningMessage}</div>
        </>
      )}

      <ImageSelector onImagesSelected={handleImagesSelected} />

      <div className="flex flex-col justify-center items-center">
        <form id="upload-form" onSubmit={handleUpload} className="flex flex-col justify-center items-center">
          <div
            className="flex flex-col justify-center items-center"
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current && fileInputRef.current.click()}
          >
            <label
              className="border-indigo-600-300 flex inline-flex cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-red-600 hover:bg-gray-100"
              style={{ maxHeight: '60vh' }}
            >
              <div
                className="aspect-ratio-square flex flex-col items-center justify-center p-6"
                style={{ maxHeight: '48vh' }}
              >
                <svg
                  className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag
                  and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  PNG or JPG (MAX. 1)
                </p>
                
                <img
                  ref={imageRef}
                  src={imagePreviewUrl? imagePreviewUrl : selectedImageUrls?.[0] || './data_input.jpeg'}
                  alt="Image preview"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '30vh',
                    objectFit: 'cover',
                  }}
                />
                {!selectedImageUrls && (
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                  Input File Size: {imageMetadata?.fileSize || '1.98'} MB
                </p>
                )}
              </div>
            </label>
            <input
              type="file"
              id="image-input"
              className="hidden"
              onChange={handleImageChange}
              ref={fileInputRef}
              accept="image/*"
            />
          </div>
        </form>
        {imagePreviewUrl && (
          <>
            <div id="quality-input">
              <label htmlFor="acuity-quality">Compression Level</label>
              <input
                type="range"
                id="acuity-quality"
                min="1"
                max="7"
                value={quality}
                onChange={handleQualityChange}
              />
              <span id="sliderValue">{quality}</span>
            </div>
            <div className="mt-6">
              <button
                className="rounded bg-red-700 px-4 py-2 font-bold text-white hover:bg-red-900"
                id="process-button"
                onClick={processImages}
              >
                Compress
              </button>
            </div>
          </>
        )}
          <div>
            <div className="mb-6 mt-6 flex w-full flex-col items-center">
              <h2 className={styles.title}>Compression Statistics</h2>
              {aaiResults && (
                <table className="stats-table min-w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                    <th className="text-left">Statistic</th>
                      <th className="text-right">AcuityAI</th>
                      {jpgResults?.jpg?.stat_file && <th className="text-right">JPEG</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {statsForDisplay?.map((stat, index) => (
                      <tr
                        key={index}
                        className={index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}
                      >
                        <td className="text-left">
                          {stat.name === 'Binary File Size'
                            ? 'Compressed File Size'
                            : stat.name}
                        </td>
                        <td className="text-right">{stat.acuity}</td>
                        {jpgResults?.jpg?.stat_file && <td className="text-right">{stat.jpeg}</td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            {!aaiResults && <img src={selectedImageUrls?.[1] || "./compression_stat_table.png"} />}
            </div>
            <div className="mb-6 mt-6 flex w-full flex-col items-center">
              <div className="mt-6">
                <button
                className="rounded bg-red-700 px-4 py-2 font-bold text-white hover:bg-red-900"
                onClick={reconstructImages}
                disabled = {!(jpgResults && aaiResults) || reconImages}
                >
                  Reconstruct
                </button>
              </div>
              
                <div className="flex min-w-full flex-col">
                  {/* <div className={styles.section}> */}
                  <h2 className="mb-2 text-center text-2xl tracking-tight text-black sm:text-2xl">
                    Image Quality Comparison
                  </h2>
                  <ImageComparator
                    aai={
                      reconImages? aaiResults?.aai?.decoded_image ||
                      './acuityai_decoded_image.bmp': selectedImageUrls?.[2] || './acuityai_decoded_image.bmp' 
                    }
                    jpg={
                      reconImages? jpgResults?.jpg?.decoded_image ||
                      './jpeg_decoded_image.bmp': selectedImageUrls?.[3] || './jpeg_decoded_image.bmp'
                    }
                  />
                </div>
              
              <div className="mt-6">
                <button
                className="rounded bg-red-700 px-4 py-2 font-bold text-white hover:bg-red-900"
                onClick={detectObjects}
                disabled = {!(jpgResults && aaiResults) || disableDetect}
                >
                  Detect objects
                </button>
              </div>
              
                <div className="min-w-full">
                  <h2 className={styles.title}>Object Detection Comparison</h2>
                  <ImageComparator
                    aai= {
                      detResults?.det?.aai? detResults.det.aai:
                      selectedImageUrls?.[4] || './acuity_detection.bmp'
                    }
                    jpg={
                      detResults?.det?.jpg? detResults.det.jpg:
                      selectedImageUrls?.[5] || './jpeg_detection.bmp'}
                  />
                </div>
            
            </div>
          </div>
      </div>
    </div>
  );
};

export default ImageCompressor;
