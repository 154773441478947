export function Header() {
  return (
    <div className="flex h-[30vh] flex-col items-center justify-center bg-white text-center">
      <div className="w-full max-w-3xl px-4 py-20 sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="flex flex-col justify-center">
            <img className="h-20" src="/acuity-ai-logo.svg" alt="" />
            <h3 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl md:text-4xl">
              Content-Aware Compression
            </h3>
          </div>
          <p className="mt-2 overflow-hidden text-ellipsis whitespace-nowrap text-center text-xs leading-tight text-gray-500 sm:text-sm md:text-base lg:max-w-full">
            World's first compression technology designed and optimized for
            Object Detection workflows.
          </p>
          {/* <div className="mt-20 sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Storage size reduction by 82.33 without compromising.{' '}
              <a href="#" className="font-semibold text-black">
                <span className="absolute inset-0" aria-hidden="true" />
                Read more <span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
