import './HowWorks.css';

export function HowWorks() {
  return (
    <div>
      <div className="flex flex-col bg-black pb-28">
        <div className="text-center">
          <div className="mx-auto max-w-2xl py-20 sm:py-20 lg:pt-20">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-4xl">
              How does it work?
            </h1>
            <p className="mt-2 text-lg font-light leading-8 text-gray-300">
              Acuity.AI's secret is in "understanding" the content before
              compressing.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              className="w-[500px] overflow-hidden rounded-lg border-2 border-gray-600"
              src="./data_input.jpeg"
            />
          </div>
          <div className="flex h-52 flex-row items-center justify-center overflow-hidden">
            <div>
              <div className="HowWorks w-[365px] rounded-lg border-2 border-gray-600 px-4 py-2 text-left">
                <p className="text-sm font-light leading-6 text-gray-400">
                  <span className="mr-1 text-xl text-gray-200">
                    Traditional compression
                  </span>
                  "wastes" bits on areas of little relevance for object
                  detection.
                </p>
              </div>
            </div>
            <div className="Arrow" />
            <div>
              <div className="HowWorks w-[365px] rounded-lg border-2 border-gray-600 px-4 py-2 text-left">
                <p className="text-sm font-light leading-6 text-gray-400">
                  <span className="mr-1 text-xl text-gray-200">
                    AcuityAI's content-aware compression
                  </span>
                  drastically reduces storage size by optimally allocating bits
                  only for areas and features that are most relevant for object
                  detection.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center">
            <div className="w-[400px]">
              <img
                src="./jpeg_bit_alloc_map.png"
                className="rounded-lg border-2 border-gray-600"
              />
              <span className="b-2 m-2 text-xl text-gray-200">
                JPEG BIT DISTRIBUTION MAP
              </span>
            </div>
            <div className="w-[60px]" />
            <div className="w-[400px]">
              <img
                src="./aai_bit_alloc_map.png"
                className="rounded-lg border-2 border-gray-600"
              />
              <span className="b-2 m-2 text-xl text-gray-200">
                AcuityAI BIT DISTRIBUTION MAP
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
