import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './CodecGraph.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const originalData = [
  [62.5, 62.7, 63, 63.5, 64],
  [52.8, 54.1, 55.7, 58.3, 62.5],
  [51.5, 53.3, 54.8, 57.2, 60.1],
  [50.9, 52.4, 54.2, 56, 58.7],
  [50, 51.3, 53, 54.8, 57.9],
  [50, 51, 52, 54, 56],
];

export const options: ChartOptions<any> = {
  scales: {
    x: {
      title: {
        display: true,
        text: 'Bits Per Pixel (BPP)',
      },
    },
    y: {
      title: {
        display: true,
        text: 'mAP(%)',
      },
    },
  },
};

export const data = {
  labels: [0.07, 0.709, 1.349, 1.988, 2.627],
  datasets: [
    {
      label: 'AcuityAI-c5 (-86.32)',
      data: originalData[0],
      borderColor: 'red',
      fill: false,
    },
    {
      label: 'Cheng2020 (-68.54)',
      data: originalData[1],
      borderColor: 'blue',
      fill: false,
    },
    {
      label: 'x265-Intra (-56.28)',
      data: originalData[2],
      borderColor: 'pink',
      fill: false,
    },
    {
      label: 'JPEG (anchor)',
      data: originalData[3],
      borderColor: 'purple',
      fill: false,
    },
    {
      label: 'Uncompressed',
      data: originalData[4],
      borderColor: 'black',
      fill: false,
    },
    {
      label: 'GroundTruth mAP',
      data: originalData[5],
      borderColor: 'green',
      fill: false,
    },
  ],
};

export function CodecGraph() {
  return (
    <div className="flex flex-col items-center bg-white pb-28 pt-20">
      <div className="w-full text-center">
        <h1 className="text-3xl lg:max-w-ful mt-2 overflow-hidden text-ellipsis whitespace-nowrap text-center leading-tight text-black sm:text-2xl md:text-4xl">
          Acuity.AI results in lower file sizes AND better detection accuracy.
        </h1>
      </div>
      <div className="flex w-[70vw] justify-center px-24 py-12">
        {/* <Line options={options} data={data} /> */}
        <img
          src="./perfGraph.png"
          className="rounded-lg border-2 border-gray-600"
        />
      </div>
      <div className="flex w-[70vw]  flex-row gap-20">
        <div className="ItemTradeoff w-[30vw] px-4 py-2 text-left">
          <p className="text-2xl text-black">
            Acuity.AI reduces the storage size by 82.33% with negligible effect
            on Detection Accuracy.
          </p>
        </div>
        <div className="flex-1" />
        <div className="ItemTradeoffRight w-[30vw] px-4 py-2 text-right">
          <p className="text-2xl text-black">
            Detection accuracy is 52% higher with AcuityAI when compared to
            JPEG.
          </p>
        </div>
      </div>
    </div>
  );
}
