import React, { useState } from 'react';
import { ImageComparator } from './ImageComparator';
import ImageCompressor from './ImageCompressor';

const styles = {
  section:
    'mb-14 flex w-full max-w-[1000px] flex-col items-center justify-center',
  title: 'mb-6 text-center text-2xl tracking-tight text-black sm:text-2xl',
};

export function Upload() {
  return (
    <div className="flex flex-col items-center">
      <div className={styles.section}>
        <h2 className={styles.title}>Select an image</h2>
        <ImageCompressor />
        </div>
    </div>
  );
}
